.project-page {
  position: fixed;
  background-color: #a4dee1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.project-page .layer2 {
  position: fixed;
  top: 0;
  height: 50vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -100;
  background-image: url("./layer3test.svg");
}
.project-page .layer3 {
  position: fixed;
  bottom: 0;
  height: 50vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -100;
  background-image: url("./layer2test.svg");
}
.project-page h1 {
  margin: 0;
}
.project-page h1.animate-project-text {
  color: #fff;
  position: absolute;
  font-size: 40px;
  left: 10%;
  top: 1%;
  font-family: "Coolvetica";
  font-weight: 400;
  z-index: 2;
  display: inline !important;
  line-height: 100px;
  
}
.project-page .project-container {
  z-index: 3;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-content: center;
  margin: 0 auto;
  gap: 5vh;
  top: 350px;
  margin-left: 20vh;
  margin-right: 10vh;
  text-align: center;
}
.project-page .particle {
  z-index: 1;
}
a {
  text-decoration: none;
}
@media screen and (max-width: 900px) {
  .project-page {
    text-align: center;
    overflow-y: scroll;
    overflow-x: hidden;
    h1 {
      line-height: 80px;
      padding: 0;
    }
    h1.animate-project-text {
      color: #fff;
      position: absolute;
      font-size: 8vw;
      left: 13%;
      font-family: "Coolvetica";
      z-index: 2;
      text-align: center;
      top: 5%;
    }
    .project-container {
      z-index: 3;
      position: relative;
      display: inline-grid;
      grid-template-columns: none;
      align-content: center;
      gap: 5vh;
      top: 350px;
      margin-left: 24px;
      margin-right: 0;
      padding-bottom: 30px;
      text-align: center;
    }
  }
}
