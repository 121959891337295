.nav-bar {
  background: #181818;
  width: 70px;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 30px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #8fbc8f;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: "HOME";
        }
      }
    }

    a.about-link {
      &:after {
        content: "ABOUT";
      }
    }
    a.projects-link {
      &:after {
        content: "PROJECTS";
      }
    }
    a.contact-link {
      &:after {
        content: "CONTACT";
      }
    }

    a.active {
      svg {
        color: #8fbc8f;
      }
    }
  }
  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 30px;
        line-height: 16px;

        &:hover svg {
          color: #8fbc8f;
        }
      }
    }
  }
}
.hamburger-icon, .close-icon {
  display: none;
}
@media screen and (max-width: 900px) {
  .nav-bar {
    background: transparent;
    position: fixed;
    height: auto;
    min-height: auto;
    z-index: 10;
    right: 0;
  
    nav {
      display: none;
    }
 
    nav {
      width: 100%;
      height: 100%;
      background: #181818;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 10;
      margin: 0;
      
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a svg {
        opacity: 1 !important;
      }

      &.mobile-show {
        display: block;
      }
    }
    .hamburger-icon,
    .close-icon {
      display: block;
      position: absolute;
      color: white;
      background-color: darkslategray;
      padding: 5px;
      border-radius: 5px;
      top: 25px;
      right: 25px;
      height: 40px;
    }
    ul{
      position: fixed;
      width: 40px;
      height: 90px;
      top: 83%;
      right: 83%;
      background-color: #8fbc8f;
      border-radius: 25px;
    }
  }
}
