.about-page .animate-wrapper {
  position: absolute;
  left: 150px;
  top: 60px;
  color: #fff;
  font-size: 40px;
  margin: 0;
  font-family: "Coolvetica";
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 8px;
}
.about-page .text-wrapper {
  position: absolute;
  inline-size: 40%;
  left: 160px;
  top: 240px;
  color: #fff;
  font-size: 25px;
  margin: 0;
  font-family: "Coolvetica";
  font-weight: 400;
  animation: fadeIn 1s 1s backwards;
}
.about-page .content {
  position: absolute;
  left: 50%;
  top: 15%;
  color: #fff;
  font-size: 30px;
  margin: 0;
  font-family: "Coolvetica";
  font-weight: 400;
  letter-spacing: 1px;
  background-color: #2f4f4f;
  border-radius: 300rem;
}

.about-page .layer1 {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -100;
  background-image: url("./layer1-about.svg");
}

@media screen and (max-width: 900px) {
  .about-page {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    .text-zone {
      padding: 0px;
      margin: 0px;
      height: 100%;
      width: 100%;
      .animate-wrapper {
        position: absolute;
        top: 3%;
        left: 10%;
        color: #fff;
        font-size: 5vw;
        font-family: "Coolvetica";
        font-weight: 400;
        letter-spacing: 3px;
        line-height: 8px;
        text-align: center;
        padding-bottom: 200px;
      }
    }
    .text-wrapper {
      position: absolute;
      padding-top: 20%;
      top: 6%;
      width: 65%;
      left: 6%;
      color: #fff;
      font-size: 3.5vw;
      margin: 0;
      font-family: "Coolvetica";
      font-weight: 400;
      letter-spacing: 3px;
      ul{
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 0;
        margin-bottom: 0;
        padding: 0;
        
      }
      .first-list {
        display: inline-block;
        width: calc(50% - 1px);
        background-color: #2f4f4f;
        border-radius: 25px;
        background-size: 100px 100px;
        padding: 5%;
      }
      .second-list {
        position: absolute;
        left: 50vw;
        display: inline-block;
        width: calc(50% - 1px);
        background-color: #2f4f4f;
        border-radius: 25px;
        padding: 5%;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
.about-page .content {
  display: none;
  }
}