html {
  font-size: 62.5%;
  overflow:hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  height: 100%;
}

body {
  height: 100%;
  overflow:hidden;
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 900px) {
  body {
    overflow: visible;
  }
}