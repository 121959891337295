.stage-cube-cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -150px;
  
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);
  z-index: 5;
  
  //padding: -200px;
  //position: fixed;

  div {
    position: fixed;
    width: 15vw;
    height: 15vw;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px #8fbc8f;
    z-index: 5;
  }

  .face1 {
    transform: translateZ(7.5vw);
    color: #dd0031;
  }
  .face2 {
    transform: rotateY(90deg) rotate(270deg) translateZ(7.5vw);
    color: #f06529;
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(7.5vw);
    color: #28a4d9;
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(7.5vw);
    color: #5ed4f4;
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(7.5vw);
    color: #efd81d;
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(7.5vw);
    color: #ec4d28;
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

.home-page .text-zone {
  position: fixed;
  left: 10%;
  top: 30%;
  transform: translateY(-50%);
  width: 40%;
  max-height: 90%;
}

.home-page .text1 {
  color: #fff;
  font-size: 80px;
  margin: 0;
  font-family: "Coolvetica";
  font-weight: 400;

  .home-page .img {
    width: 32px;
    margin-left: 20px;
    opacity: 0;
    height: auto;
    animation: rotateIn 1s linear both;
    animation-delay: 1.4s;
  }
}
.home-page .text2 {
  color: #8fbc8f;
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  font-family: "Coolvetica";
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8s backwards;
}
.home-page .flat-button {
  color: #8fbc8f;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: "Coolvetica";
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #8fbc8f;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover {
    background: #8fbc8f;
    color: #333;
  }
}

.layer1 {
  background-image: url("./layer1.svg");
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -100;
}


@media only screen and (max-width: 900px) {
  .layer1 {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }
    .stage-cube-cont{
      margin-top: 20%;
    }
    .text1 {
      font-size: 14vw;
      color: rgb(255, 255, 255);
      margin-left: 5vw;
    }
    .text2 {
      width: 75%;
      //color:#fff;
      margin-left: 5vw;
    }
    .flat-button {
      color: #fff;
      border: 1px solid #fff;
      margin-left: 5vw;
    }
    .cubespinner {
      div {
        width: 150px;
        height: 150px;
        font-size: 100px;
      }
      .face1 {
        transform: translateZ(77px);
      }
      .face2 {
        transform: rotateY(90deg) rotate(270deg) translateZ(77px);
      }
      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(77px);
      }
      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(77px);
      }
      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(77px);
      }
      .face6 {
        transform: rotateX(-90deg) translateZ(77px);
      }
    }
  }
}
